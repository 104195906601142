import React, { useState, useEffect } from "react";
import "./App.css";

export const currentAndroidVersion = "4.00 (124)";
export const currentIosVersion = "4.00 (124)";
export const currentWebVersion = "sPRODw21";

const notes = `
- Added tutorial for site onboarding after registration.
- Modified the UI for Edit Profile in the site app.
- Fixed security alert message when signing up.
- Site users now see dynamic ETA intervals for deliveries in transit.
- Aligned "Add DDP" in project settings with current design.
- Improved dark mode look and feel for Site/Driver apps.
- Updated Project Settings for site users to a new look and feel.
- Fixed text display issues in error messages (phone number display).
- Corrected SE Translation where 3/4 of the error message was in English.
- Sub-contractor site users can now view DDP images in a project.
- Fixed misalignment of the QR Code scan overlay.
- Ensured onboarding flow starts immediately when creating a project.
- Adjusted onboarding flow text to fit within the text frame.
- Made the entire onboarding text readable on the dashboard.
- Fixed bug showing in-transit deliveries in calendar.
`;

const ReleaseNotes = () => {
	const [scrollViewHeight, setScrollViewHeight] = useState(
		window.innerHeight - 240
	);

	useEffect(() => {
		const handleResize = () => {
			setScrollViewHeight(window.innerHeight - 240);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<section className="App">
			<main className="App-main">
				<header className="App-header">
					<img src={`../img/lokate-light-logo.svg`} height={28} />
				</header>
				<section
					className="Container"
					style={{
						height: scrollViewHeight,
						border: "2px solid #ccc",
						padding: "20px",
					}}
				>
					<div className="VersionTextContent">
						android version: {currentAndroidVersion}
					</div>
					<div className="VersionTextContent">
						ios version: {currentIosVersion}
					</div>
					<div className="VersionTextContent">
						web version: {currentWebVersion}
					</div>
					<br></br>
					<hr></hr>
					<br></br>
					<br></br>
					<div
						style={{
							textAlign: "left",
							fontWeight: "bold",
							color: "darkslategray",
						}}
					>
						Latest Release notes:
					</div>
					<br></br>
					{notes.split("- ").map((line, i) => (
						<div
							style={{
								textAlign: "left",
								paddingLeft: "20px",
								paddingBottom: "5px",
								color: "darkslategray",
							}}
							key={"note_" + i}
						>
							{i != 0 && "- "}
							{line}
						</div>
					))}
				</section>
			</main>
		</section>
	);
};

export default ReleaseNotes;
