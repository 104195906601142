import "./Nav.css";
import {
	currentAndroidVersion,
	currentIosVersion,
	currentWebVersion,
} from "./ReleaseNotes";

const Nav = () => {
	const navlinkStyle = {
		textTransform: "uppercase",
		textDecoration: "none",
	};
	return (
		<div className="footer">
			<div className="footer-links">
				<a href={"/"} style={navlinkStyle}>
					LINKS
				</a>
				<span>|</span>
				<a href={"/userterms-privacypolicy"} style={navlinkStyle}>
					USER TERMS AND PRIVACY NOTICE
				</a>
				<span>|</span>
				<a href={"/releasenotes"} style={navlinkStyle}>
					RELEASE NOTES
				</a>
			</div>
			<div className="footer-versions">
				<span>Latest Android Version: 4.00 (124)</span>
				<span>/</span>
				<span>Latest iOS Version: 4.00 (124)</span>
				<span>/</span>
				<span>Latest Web Version: sPRODw21</span>
			</div>
		</div>
	);
};

export default Nav;
