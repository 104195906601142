import React, { useState, useEffect } from "react";
import "./App.css";

export const themeManager = (darkMode = false) => {
	return {
		mode: !darkMode ? "light" : "dark",
		defaultFontColour: !darkMode ? "#414141" : "#fed",
		lightFontColour: !darkMode ? "#ffffff" : "#000",
		paleAccent: !darkMode ? "#f5f7fd" : "#33376a",
		midAccent: !darkMode ? "#dbdffc" : "#dbdffc",
		altAccent: !darkMode ? "#EAECFD" : "#8c8e98",
		accentColour: !darkMode ? "#413ddf" : "#9baacc",
		accentComplimentColour: !darkMode ? "#ff7346" : "#CCBD9B",
		greyTextColour: !darkMode ? "#6c6c6c" : "#999",
		mainBackgroundColour: !darkMode ? "#fff" : "#000437",
		errorColour: !darkMode ? "#E82E00" : "indianred",
		errorComplimentaryColour: !darkMode ? "#FBDBD4" : "#FBDBD4",
		authHeaderBG: !darkMode ? ["white", "white"] : ["#000", "#000"],
		subtleToBGColour: !darkMode ? "#f4f4f4" : "#000220",
		successColour: !darkMode ? "#2CC76D" : "#198754",
		successComplimentaryColour: !darkMode ? "#E9F9F0" : "#E9F9F0",
		warningColour: !darkMode ? "#FFAA00" : "#FFAA00",
		webNavy: !darkMode ? "#2e3440" : "#2e3440",
		webSilverBorders: !darkMode
			? "rgba(100, 150, 200,0.3)"
			: "rgba(100, 150, 200,0.3)",
		textInputTextColour: !darkMode ? "rgba(0, 0, 0, 0.87)" : "#ddd",
		textInputPlaceholderColour: !darkMode ? "rgba(0, 0, 0, 0.6)" : "#ddd",
		textInputMediumColour: !darkMode ? "rgba(0, 0, 0, 0.4)" : "#fff",
		textInputBorderColour: !darkMode
			? "rgba(0, 0, 0, 0.12)"
			: "rgba(255,255,255,0.2)",
	};
};
function App() {
	const [english, setEnglish] = useState(true);
	const [scrollViewHeight, setScrollViewHeight] = useState(
		window.innerHeight - 250
	);

	useEffect(() => {
		const handleResize = () => {
			setScrollViewHeight(window.innerHeight - 250);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	let androidLink = "https://groups.google.com/g/lokate-android-beta";
	let androidTitle = "Download on Play Store";

	let iosLink = "https://testflight.apple.com/join/mQ5fD96J";
	let iosTitle = "Download on TestFlight";

	let webLink = "https://supplier.lokate.io";
	let webTitle = "Open Supplier Web";

	return (
		<section className="App">
			<main className="App-main">
				<header className="App-header">
					<img
						src={`../img/lokate-light-logo.svg`}
						height={28}
						alt={androidTitle}
					/>
					{
						<div className="language-toggle">
							<button
								className={english ? "selected" : ""}
								onClick={() => setEnglish(true)}
							>
								{" "}
								English
							</button>
							|
							<button
								className={!english ? "selected" : ""}
								onClick={() => setEnglish(false)}
							>
								{" "}
								Svenska
							</button>
						</div>
					}
				</header>
				<br></br>
				<section
					className="Container"
					style={{
						height: scrollViewHeight,
						border: "2px solid #ccc",
						padding: "20px",
					}}
				>
					{english ? (
						<div className="TextContainer">
							<h2>Welcome to Lokate!</h2>
							<br></br>
							<div
								style={{
									textAlign: "left",
									paddingTop: "20px",
									paddingBottom: "10px",
								}}
							>
								<b>Are you a Supplier? </b>
							</div>
							<p style={{ textAlign: "left" }}>
								<a href={webLink} target="_blank" title={webTitle}>
									Click here
								</a>{" "}
								to visit the supplier website
							</p>
							<div
								style={{
									textAlign: "left",
									paddingTop: "20px",
									paddingBottom: "10px",
								}}
							>
								<b>
									Are you a Distributor/Driver or work at a Construction Site?
								</b>
							</div>
							<p style={{ textAlign: "left" }}>
								To get started, scan the code with your camera or click the link
								corresponding to the platform you are using.
							</p>
						</div>
					) : (
						<div className="TextContainer">
							<h2>Välkommen till Lokate!</h2>
							<br></br>
							<div
								style={{
									textAlign: "left",
									paddingTop: "20px",
									paddingBottom: "10px",
								}}
							>
								<b>Är du Leverantör? </b>
							</div>
							<p style={{ textAlign: "left" }}>
								<a href={webLink} target="_blank" title={webTitle}>
									Klicka här
								</a>{" "}
								för att besöka leverantörens webbplats
							</p>
							<div
								style={{
									textAlign: "left",
									paddingTop: "20px",
									paddingBottom: "10px",
								}}
							>
								<b>
									Är du Distributör/Förare eller jobbar på en Byggarbetsplats?
								</b>
							</div>
							<p style={{ textAlign: "left" }}>
								För att komma igång, skanna koden med din kamera eller klicka på
								länken till den platform du använder.
							</p>
						</div>
					)}
					<div className="Code-container">
						<div className="Code">
							<a
								href={androidLink}
								style={{ color: "#413ddf", padding: "10px" }}
								title={androidTitle}
								target="_blank"
								rel="noreferrer"
							>
								Android - Play Store
							</a>
							<a
								href={androidLink}
								title={androidTitle}
								target="_blank"
								rel="noreferrer"
							>
								<img src={`../img/ANDROID-QR.png`} alt={androidTitle} />								
							</a>
						</div>
						<div className="Code">
							<a
								href={iosLink}
								style={{ color: "#413ddf", padding: "10px" }}
								title={iosTitle}
								target="_blank"
								rel="noreferrer"
							>
								iOS - TestFlight
							</a>
							<a
								href={iosLink}
								title={iosTitle}
								target="_blank"
								rel="noreferrer"
							>
								<img src={`../img/iOS-QR.png`} alt={iosTitle} />
							</a>
						</div>
					</div>
				</section>
			</main>
		</section>
	);
}

export default App;
